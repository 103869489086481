import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { navigateToQuery, setUserLocalStorage, fetchService } from './Util';
import PageHeader from './PageHeader';

const theme = createTheme();

const Login = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams] = useSearchParams();
    const path = searchParams.get('path');

    const [submitting, setSubmitting] = useState(() => false);
    const [error, setError] = useState(() => location?.state?.errorMessage || null);
    const [errorDetails, setErrorDetails] = useState(() => null);

    const [successMessage, setSuccessMessage] = useState(() => location?.state?.successMessage || null);

    useEffect(() => {
        document.title = 'Log In - EODmetrics';
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        fetchService('POST', '/service/login', {
            email: document.getElementById('email').value,
            password: document.getElementById('password').value,
        })
            .then((response) => response.json())
            .then((data) => {
                setSubmitting(false);
                if (data['error']) {
                    setError(data['error']);
                    if (data['errorDetails']) {
                        setErrorDetails(data['errorDetails']);
                    } else {
                        setErrorDetails(null);
                    }
                } else {
                    setError(null);
                    setErrorDetails(null);
                    setUserLocalStorage({ status: data['status'] });

                    if (path) {
                        navigate(path);
                    } else {
                        navigateToQuery(navigate);
                    }
                }
            })
            .catch((err) => {
                setSubmitting(false);
                setError('Error: ' + err.toString());
                setErrorDetails(null);
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PageHeader title="Log In" />
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    {error && (!errorDetails || errorDetails['login']) &&
                        <Alert id="error-container" onClose={() => { setError(null) }} severity="error" sx={{ m: 1 }}>{error}</Alert>
                    }
                    {successMessage && !error &&
                        <Alert id="success-container" onClose={() => { setSuccessMessage(null) }} severity="success" sx={{ m: 1 }}>{successMessage}</Alert>
                    }
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    id="email"
                                    required
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    error={errorDetails && errorDetails?.field === 'email'}
                                    helperText={errorDetails && errorDetails?.field === 'email' ? error : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="password"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    error={errorDetails && errorDetails?.field === 'password'}
                                    helperText={errorDetails && errorDetails?.field === 'password' ? error : ''}
                                />
                            </Grid>
                        </Grid>
                        <Box style={{ minHeight: '40px' }} sx={{ display: 'flex', mt: 3, mb: 2 }}
                            justifyContent="center"
                        >
                            {submitting ? (
                                <CircularProgress size={40} />
                            ) : (
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                >
                                    Log In
                                </Button>
                            )}
                        </Box>
                        <Grid container>
                            <Grid item xs>
                                <Typography variant="body2">
                                    <Link component={RouterLink} to="/forgot-password">Forgot password?</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider >
    )
}

export default Login;
