import { React, useEffect, useState } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";

import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { getUserLocalStorage, setUserLocalStorage, navigateToQuery, fetchService } from './Util';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     width: '25ch',
        //     '&:focus': {
        //         width: '25ch',
        //     },
        // },
    },
}));

const Header = (props) => {
    const [user, setUser] = useState(() => getUserLocalStorage());

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const pages = [
        {
            name: 'Query',
            action: () => {
                navigateToQuery(navigate);
            },
            renderIf: () => {
                return true;
            }
        },
        {
            name: 'API Docs',
            action: () => {
                navigate('/docs');
            },
            renderIf: () => {
                return true;
            }
        },
        {
            name: 'Log In',
            action: () => {
                navigate('/login');
            },
            renderIf: () => {
                return !user.status;
            }
        },
    ];

    const userLinks = [
        {
            name: 'Account',
            action: () => {
                navigate('/account');
            },
            renderIf: () => {
                return true;
            },
            disabledIf: () => {
                return false;
            },
        },
        {
            name: 'Saved Queries',
            action: () => {
                navigate('/saved-queries');
            },
            renderIf: () => {
                return true;
            },
            disabledIf: () => {
                return user.status < 2;
            },
        },
        {
            name: 'Log Out',
            action: () => {
                fetchService('POST', '/service/logout', {})
                    .then((response) => {
                        if (!response.ok) {
                            throw Error(response.statusText);
                        }
                        return response
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        setUserLocalStorage({ status: 0 });
                        navigate('/login', { state: { successMessage: 'You have successfully logged out.' } });
                    })
                    .catch((err) => { });
            },
            renderIf: () => {
                return true;
            },
            disabledIf: () => {
                return false;
            },
        },
    ];

    useEffect(() => {
        setUser(getUserLocalStorage());
        fetchService('GET', '/service/user-status', {})
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response
            })
            .then((response) => response.json())
            .then((data) => {
                setUser(data);
                setUserLocalStorage(data);
            })
            .catch((err) => { });

        document.getElementById('search-input-ticker') && document.getElementById('search-input-ticker').blur();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <AppBar position="static">
            <Container maxWidth={false}>
                <Toolbar disableGutters sx={{
                    display: { xs: 'flex', sm: 'none' }
                }} >
                    <Typography
                        variant="h5"
                        noWrap
                        component={RouterLink}
                        to="/"
                        sx={{ color: 'inherit', textDecoration: 'none' }}
                    >
                        EODmetrics
                    </Typography>
                </Toolbar>
                <Toolbar disableGutters>
                    <Typography
                        variant="h5"
                        noWrap
                        component={RouterLink}
                        to="/"
                        sx={{ display: { xs: 'none', sm: 'flex' }, color: 'inherit', textDecoration: 'none', mr: 3 }}
                    >
                        EODmetrics
                    </Typography>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <Autocomplete
                            id="search-input-ticker"
                            size="small"
                            autoHighlight
                            onChange={(_, value) => {
                                navigate('/ticker/' + (value.ticker || value));
                            }}
                            disablePortal
                            freeSolo
                            value={null}
                            options={props.securityList}
                            getOptionLabel={(_) => ''}
                            renderOption={props.renderTickerOption}
                            filterOptions={props.filterOptionsTickers}
                            renderInput={(params) => {
                                const { InputLabelProps, InputProps, ...rest } = params;
                                return (
                                    <StyledInputBase
                                        {...params.InputProps} {...rest}
                                        placeholder="Ticker or company name"
                                        sx={{ width: { sm: 250, md: 325, lg: 450 } }}
                                    />
                                )
                            }}
                        />
                    </Search>

                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => page.renderIf() && (
                            <Button
                                color="inherit"
                                key={page.name}
                                onClick={() => {
                                    handleCloseNavMenu();
                                    page.action();
                                }}
                                sx={{ my: 2, ml: 3, display: 'block' }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="page links"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => page.renderIf() && (
                                <MenuItem
                                    key={page.name}
                                    onClick={() => {
                                        handleCloseNavMenu();
                                        page.action();
                                    }}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {user.status >= 1 &&
                        <Box sx={{ flexGrow: 0, ml: 3 }}>
                            <IconButton
                                sx={{ p: 0 }}
                                size="large"
                                aria-label="user links"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenUserMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                sx={{ mt: '35px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {userLinks.map((userLink) => userLink.renderIf() && (
                                    <MenuItem
                                        disabled={userLink.disabledIf()}
                                        key={userLink.name}
                                        onClick={() => {
                                            handleCloseUserMenu();
                                            userLink.action();
                                        }}>
                                        <Typography textAlign="center">{userLink.name}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    }
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default Header;
