import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import PageHeader from './PageHeader';

import { getUserLocalStorage, setUserLocalStorage, fetchService } from './Util';

const theme = createTheme();

const Signup = (props) => {
    const [user] = useState(() => getUserLocalStorage());

    const [submitting, setSubmitting] = useState(() => false);
    const [error, setError] = useState(() => null);
    const [errorDetails, setErrorDetails] = useState(() => null);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const qid = searchParams.get('qid');

    useEffect(() => {
        if (user.status >= 1) {
            navigate('/account');
            return;
        }

        document.title = 'Sign Up - EODmetrics';
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        fetchService('POST', '/service/signup', {
            email: document.getElementById('email').value,
            password: document.getElementById('password').value,
        })
            .then((response) => response.json())
            .then((data) => {
                setSubmitting(false);
                if (data['error']) {
                    setError(data['error']);
                    if (data['errorDetails']) {
                        setErrorDetails(data['errorDetails']);
                    } else {
                        setErrorDetails(null);
                    }
                } else {
                    setError(null);
                    setErrorDetails(null);
                    setUserLocalStorage({ status: 1 });
                    navigate('/subscribe' + (qid ? '?qid=' + qid : ''));
                }
            })
            .catch((err) => {
                setSubmitting(false);
                setError('Error: ' + err.toString());
                setErrorDetails(null);
            });
    }

    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container justifyContent="center" sx={{ marginTop: '30px' }}>
                        <Grid item>
                            <Typography variant="body1">
                                Sorry, we are not accepting new user registrations at this time.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" sx={{ my: 2 }}>
                        <Grid item>
                            <Typography variant="body1">
                                Already have an account? <Link component={RouterLink} to="/login">Log in</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </ThemeProvider >
    )
}

export default Signup;
