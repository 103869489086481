import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { navigateToQuery, setUserLocalStorage, getUserLocalStorage, fetchService } from './Util';
import PageHeader from './PageHeader';

window.authNetResponseHandlerWrapper = null;

const Subscribe = (props) => {

    return (
        <>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container justifyContent="center" sx={{ marginTop: '30px' }}>
                        <Grid item>
                            <Typography variant="body1">
                                Sorry, we are not accepting new subscriptions at this time.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default Subscribe;
